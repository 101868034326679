//
// Root
//

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }    
}

body {
    display: flex;
    flex-direction: column;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

canvas {
    user-select: none;
}

// Angular integration
router-outlet {
    display: none;
}


// ui-css
.dashtoolbar{
    margin-left: 2.5rem; 
    padding-top: 1rem;
}

.btntextwhite{
    color: white;
}

#cardred{
    background-color: #F23D2F;
}

#cardGreen{
    background-color: #3AC47D;
}

#cardGrey{
    background-color: #354259;
}

#cardYellow{
    background-color: #F7B924;
}

#cardPurple{
    background-color: #5028A4;
}

#cardBlue{
    background-color: #16AAFF;
}
#cardtextwhite{
    color: white;
}

#iconsize{
    width: 30px; 
    height: 30px;
}

#dashbranchtable{
    background-color: #16AAFF; 
    padding-bottom: 1rem;
}

#branchnames{
    padding-left: 1rem; 
    text-align: left;
}

#bottommargin{
    margin-bottom: -3rem;
}

#width153-px{
    width: 153px;
}

#width200-px{
    width: 200px;
}

#textallignleft{
    text-align: left;
}

#trow{
    background-color: #F8C0C0; 
    color: black;
}

#tablebody{
    border: 1px solid white ;
}

#trowyellow{
    background-color: #FDFA72; 
    color: black;
}

#trowgreen{
    background-color: #A3E5C4;
    color: black;
}


#formbgwhite{
    background-color: white;
    padding: 50px;
}

#frminpt{
    width: 100%;
    display: inline-flex; 
}

#w-500px{
    width: 500%;
}
.w-500px{
    width: 500%;
}

#w-500{
    width: 500%; 
    padding-left: 20px;
}

.rqstfrmcheck{
    border: 3px solid grey;
}

.eftfields{
    display:none; 
    margin-left: -2rem;
}

.alertescalationhead{
    width: 193px;
}

.w-100{
    width: 100%;
}

#leftmargin{
    margin-left: 2rem;
}
.leftmargin1{
    margin-left: 2rem;
}

.topmargin{
    margin-top: 3rem;
}

.selectbranchcard{
    width: 100%; margin: auto; padding: 10px;
}

.mleft-1rem{
    margin-left: 1rem;
}

.textallignc-center{
    text-align: center;
}
#textallignc-center1{
    text-align: center;
}

.pleft-3rem{
    padding-left: 3rem;
}
.pleft-4-3rem{
    padding-left: 4.3rem;
}
.pleft-4rem{
    padding-left: 4rem;
}
.pleft-1-3rem{
    padding-left: 1.3rem;
}

.paddingleft-8-8{
    padding-left: 8.8rem;
}

.sign{
    float: right; width: 300px;
}

.dflex{
    display: flex;
}

.sign2{
    display: flex; padding-bottom:5rem;
    padding-top: 5rem;
}

#zindex{
 z-index: -1px;
}

.check1{
    border: 2px solid grey;
}

.check2{
    margin-left: 1rem; border: 2px solid grey;
}

#padding-3px{
    padding: 3px;
}

.theader1{
    text-align: center; background-color: white;
}

.theader2{
    text-align: center; background-color: #F0F4F8;
}

.bg-green{
    background-color: #18a689;
}

.mtop{
    margin-top: -2rem;
}


.h-500px{
    height: 500px;
}

#height-500px{
    height: 500px;
}

#height-400px{
    height: 300px;
}

#theader3{
    background-color: rgb(240, 244, 248); width: 242.406px; color: black;
}

.tborder{
    border-color: #DDDDDD; border-bottom: #DDDDDD solid;
}

#tborder{
    border-color: #DDDDDD; border-bottom: #DDDDDD solid;
}

.w-900px{
    width: 900px;
}

.selbtn{
    margin-top: 3rem; margin-left: 55rem;
}

.tblack{
    color: black;
}

#tblack{
    color: black;
}

.pointer{
    cursor: pointer;
}
#pointer{
    cursor: pointer;
}

#row-red{
    background-color: #FE5152; color: white;
}

.checkinventroy{
    margin: auto; width: 50%; padding: 10px;
}

#row-hold{
    background-color: #FFB97E; color: black;
}

#row-tin{
    background-color: #89CFF0; color: black;
}

#row-tout{
    background-color: #F2DBDB; color: black;
}

#row-suspcius{
    background-color: #FE5152; color: white;
}

.p-50px{
    padding: 50px;
}

.bold{
    font-weight: bold
}

.form-sign{
    float: right; width: 300px;
}

.d-none{
    display:none
}

.pleft-4rem{
    padding-left: 4rem;
}
.pleft-9-9rem{
    padding-left: 9.9rem;
}
.pleft-13-4rem{
    padding-left: 13.4rem;
}
.pleft-8-1rem{
    padding-left: 8.1rem;
}
.pleft-3rem{
    padding-left: 3rem;
}

.hidediv{
    display: none;
}

.hidediv.active{
    display: flex;
}

.toolbar{
    // margin-left: 2.5rem; 
    padding-top: 1rem;
}

.br-tbl{
    text-align: left; border: 1px solid #C6C7CC;
}

#br-subdiv{
    padding-left: 5px;
}

.in-flx{
    display: inline-flex;
}

.p-left1rem{
    padding-left: 1rem;
}

.p-right1rem{
    padding-right: 1rem;
}

.m-left-2rem{
    margin-left: -2rem;
}

.h-204px{
    height: 204px;
}

.bg-color{
    background-color: var(--bs-body-color)  
}

.hold-modal{
    background-color: #18a689;
}

