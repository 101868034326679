//
// Indicator
//

.indicator-progress {
    display: initial;

    [data-kt-indicator="on"] > & {
        display: inline-block;
    }
}

.indicator-label {
    [data-kt-indicator="on"] > & {
        display: none;
    }
}